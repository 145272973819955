<template>
  <div
    :class="rootClasses"
    class="Button"
    @click="onClick">
    <span class="Text">{{ text }}</span>
    <div class="LoadingWrap"/>
  </div>
</template>

<script>
export default {
  name: 'Button',
  emits: ['button-click'],
  props: {
    text: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: 'salmon', // 'salmon' | 'blue'
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'medium', // 'small' | 'medium' | 'large'
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    rootClasses() {
      return {
        IsDisabled: this.isDisabled,
        IsLoading: this.isLoading,
        ColorSalmon: this.color === 'salmon' && !this.outlined,
        OutlinedSalmon: this.color === 'salmon' && this.outlined,
        ColorBlue: this.color === 'blue' && !this.outlined,
        OutlinedBlue: this.color === 'blue' && this.outlined,
        SizeSmall: this.size === 'small',
        SizeMedium: this.size === 'medium',
        SizeLarge: this.size === 'large',
        IsFullWidth: this.fullWidth,
      }
    },
  },
  methods: {
    onClick() {
      if (this.isDisabled) {
        return
      }

      if (this.isLoading) {
        return
      }

      this.$emit('button-click')
    },
  },
}
</script>

<style lang="stylus" scoped>
  .Button
    position relative
    display flex
    align-items center
    justify-content center
    cursor pointer
    box-shadow 0 2px 3px rgba(0, 0, 0, 0.3)
    .LoadingWrap
      display none
    .Text
      text-align center
      white-space nowrap
      // text-transform uppercase
      user-select none
    &.IsLoading
      .Text
        opacity 0
      .LoadingWrap
        position absolute
        top 0
        left 0
        display flex
        align-items center
        justify-content center
        width 100%
        height 100%
        &:after
          content ''
          display block
          box(20px)
          border-radius 50%
          border 2px solid transparent
          animation: loading infinite .75s linear

    // Colors
    // - Salmon
    &.ColorSalmon
      background-color $color_salmon
      .Text
        color #fff
      .LoadingWrap
        &:after
          border-left-color #fff
          border-right-color #fff
      &.IsDisabled
        background-color $color_grey_light
    &.OutlinedSalmon
      background-color #fff
      border 1px solid $color_salmon
      .Text
        color $color_salmon
      .LoadingWrap
        &:after
          border-left-color $color_salmon
          border-right-color $color_salmon
      &.IsDisabled
        background-color $color_grey_light

    // - Blue
    &.ColorBlue
      background-color $color_blue_base
      box-shadow 0 2px 3px rgba($color_blue_dark_1, 0.3)
      .Text
        color #fff
      .LoadingWrap
        &:after
          border-left-color #fff
          border-right-color #fff
      &.IsDisabled
        background-color $color_grey_light
    &.OutlinedBlue
      background-color $color_blue_light_1
      border 1px solid $color_blue_base
      box-shadow 0 2px 3px rgba($color_blue_dark_1, 0.3)
      .Text
        color $color_blue_base
      .LoadingWrap
        &:after
          border-left-color $color_blue_base
          border-right-color $color_blue_base
      &.IsDisabled
        background-color $color_grey_light

    &.SizeSmall
      padding 8px 10px 7px
      font-size 0.875rem
    &.SizeMedium
      min-height 50px
      padding 11px 20px 9px
    &.SizeLarge
      min-height 60px
      padding 20px 50px
      .Text
        font-size 1.5rem
      .LoadingWrap
        &:after
          box(30px)
    &.IsFullWidth
      width 100%
</style>
