<template>
  <div>
    <!-- Backdrop -->
    <BackDrop
      @back-drop-click="onBackdropClick"
      :is-showing="isShowing" />

    <!-- Sidepane -->
    <transition name="fade-in-delay">
      <div
        v-if="isShowing"
        class="DialogWrap"
        @click="onBackdropClick">
        <div
          class="Dialog"
          :class="dialogClasses"
          @click.stop>
          <component
            :is="useComponent"
            @close-dialog="onCloseDialog"
            v-bind="{ ...componentProps }"
            @pass-to-parent="onPassToParent" />
          <div
            class="CloseWrap"
            @click="onCloseClick">
            <InlineSVG :src="require('@/assets/svg/close.svg')" />
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import BackDrop from '@/components/BackDrop'

export default {
  name: 'Dialog',
  emits: ['close'],
  props: {
    useComponent: {
      type: Object,
      required: true,
    },
    componentProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isShowing: {
      type: Boolean,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: 'small',
    },
    easyClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    dialogClasses() {
      return {
        WidthSmall: this.width === 'small',
      }
    },
  },
  methods: {
    onBackdropClick() {
      if (!this.easyClose) {
        return
      }

      this.onCloseDialog()
    },
    onCloseClick() {
      this.onCloseDialog()
    },
    onCloseDialog() {
      this.$emit('close')
    },
    onPassToParent(event) {
      this.$emit(event.name, event.value)
    },
  },
  components: {
    BackDrop,
  },
}
</script>

<style lang="stylus" scoped>
  .DialogWrap
    position fixed
    top 0
    left 0
    box(100%)
    z-index $z_modal
    display flex
    align-items center
    justify-content center

  .Dialog
    position relative
    background-color #fff
    min-height 200px
    max-height 83%
    margin-top 10%
    margin-bottom 20%
    padding 25px
    overflow auto
    box-shadow 0 3px 7px rgba(0, 0, 0, 0.3)
    &.WidthSmall
      max-width 90%
      width 500px

  .CloseWrap
    position absolute
    top 0
    right 0
    box(40px)
    padding 12px
    cursor pointer
    svg
      fill $color_grey_light
</style>
