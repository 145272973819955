<template>
  <div
    class="InputField"
    :class="rootClasses">
    <div
      :class="{ 'HasIcon': icon }"
      class="InputWrap">
      <input
        ref="inputField"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :name="name"
        :autocomplete="disableAutoSuggest ? 'off' : 'on'"
        @input='onInput'
        @focus="onFocus"
        @blur="onBlur"
        @keyup.enter="onEnter" />
      <div
        class="InputIcon"
        v-if="icon">
        <component :is="iconToUse" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: [
    'on-update',
    'focus',
    'blur',
    'enter',
  ],
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'grey', // 'white' | 'grey'
    },
    focusTrigger: {
      type: Number,
      required: false,
      default: 0,
    },
    disableAutoSuggest: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      return {
        BackgroundColorGrey: this.backgroundColor === 'grey',
        BackgroundColorWhite: this.backgroundColor === 'white',
      }
    },
    iconToUse() {
      return ''
    },
  },
  watch: {
    focusTrigger() {
      this.$refs.inputField.focus()
    },
  },
  methods: {
    onInput(e) {
      this.$emit('on-update', { name: this.name, value: e.target.value })
    },
    onFocus(e) {
      this.$emit('focus', { name: this.name, value: e.target.value })
    },
    onBlur(e) {
      this.$emit('blur', { name: this.name, value: e.target.value })
    },
    onEnter(e) {
      e.target.blur()
      this.$emit('enter', { name: this.name, value: e.target.value })
    },
  },
}
</script>

<style lang="stylus" scoped>
.InputField
  margin-bottom 15px
  &.BackgroundColorGrey
    input
      background-color $color_grey_lightest
  &.BackgroundColorWhite
    input
      background-color #fff

.InputWrap
  position relative
  input
    height 50px
    border 1px solid $color_grey_lighter
    padding-left 10px
    padding-right 10px
  &.HasIcon
    input
      padding-left 40px

.InputIcon
  position absolute
  top 0
  left 0
  width 40px
  height 40px
  padding 10px
  svg
    fill $color_grey

</style>
