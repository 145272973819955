<template>
  <div class="LoginEmailSentModal">
    <span class="Title">{{ mixWB('LOGIN_EMAIL_SENT_TITLE') }}</span>
    <span>{{ mixWB('LOGIN_EMAIL_SENT_TEXT_1') }}</span>
    <span v-html="mixWB('LOGIN_EMAIL_SENT_TEXT_2')"/>
  </div>
</template>

<script>
export default {
  name: 'LoginEmailSentModal',

}
</script>

<style lang="stylus" scoped>
  .LoginEmailSentModal
    padding 30px 30px 70px
    span
      display block
      margin-bottom 10px
      text-align center
    .Title
      text-transform uppercase
      font-weight bold
      margin-bottom 20px
      font-size 1.25rem
</style>
