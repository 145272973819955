<template>
  <transition name="fade">
    <div
      v-if="isShowing"
      class="BackDrop"
      @click="onClick"/>
  </transition>
</template>

<script>
export default {
  props: {
    isShowing: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('back-drop-click')
    },
  },
}
</script>

<style lang="stylus" scoped>
  .BackDrop
    position fixed
    top 0
    left 0
    z-index $z_backdrop
    width 100%
    height 100vh
    background-color rgba(0, 0, 0, 0.25)
</style>
